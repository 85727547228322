import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
function Discord() {
  return (
    <>
        <head>
            <meta http-equiv="refresh" content="0; url=https://discord.gg/Pq6W6zSE2v    " />
        </head>
    </>
    );
    }

export { Discord };